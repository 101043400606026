.rain-vip-hosts {
  padding-top: 40px;

  .h2 {
    margin-bottom: 50px;

    @include media-breakpoint-down(md) {
      margin-bottom: 30px;
    }
  }

  &__col {
    &-image {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      &-container {
        max-width: 270px;
        width: 100%;

        @include media-breakpoint-down(xxxl) {
          max-width: 254px;
        }

        @include media-breakpoint-down(xxl) {
          max-width: 216px;
        }

        @include media-breakpoint-down(xl) {
          max-width: 219px;
        }

        @include media-breakpoint-down(lg) {
          max-width: calc(50% - 8px);
        }

        &:nth-child(n+5) {
          margin-top: 100px;
        }

        &:nth-child(n+3) {
          @include media-breakpoint-down(lg) {
            margin-top: 30px;
          }
        }

        a {
          pointer-events: auto;

          &:hover {
            picture {
              &:after {
                border-color: $c-rain-orange;
              }

              img {
                transform: scale(1.1);
              }
            }
          }

          picture {
            margin-bottom: 15px;
            border-radius: 24px;
            position: relative;
            overflow: hidden;
            isolation: isolate;
            width: 100%;

            &:after {
              content: "";
              position: absolute;
              border: 4px solid transparent;
              width: calc(100% - 8px);
              height: calc(100% - 8px);
              top: 0;
              left: 0;
              transition: .3s ease;
              border-radius: 24px;
            }

            img {
              transition: .3s ease;
              width: 100%;
              object-position: center;
              object-fit: cover;
              -webkit-backface-visibility: hidden;
              -moz-backface-visibility: hidden;
              -webkit-transform: translate3d(0, 0, 0);
              -moz-transform: translate3d(0, 0, 0);
              aspect-ratio: 1/1;
            }
          }

          .h5 {
            font-size: 18px;
            line-height: 1;
            color: $c-black;
            font-weight: 400;
            margin-bottom: 5px;
          }

          p {
            font-size: 14px;
            line-height: 1.29;
            color: $c-black;
            margin-bottom: 0;
            font-weight: 400;
          }
        }
      }

      //each image transform
      &-container {
        pointer-events: none;
      }
    }
  }
}