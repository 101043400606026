.rain-vip-header {
  height: 100px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9;
  transition: .3s ease;

  @include media-breakpoint-down(lg) {
    height: 75px;
  }

  &.active {
    background-color: $c-white;

    .rain-vip-header-hamburger {
      border-color: $c-orange-1;

      &:before,
      &:after {
        background-color: $c-orange-1;
      }
    }

    .rain-vip-header-nav {
      ul {
        li {
          a {
            color: $c-rain-blue;
          }
        }
      }
    }

    .rain-vip-header-logo {
      svg {
        path {
          fill: $c-rain-blue;
        }
      }
    }
  }

  &-logo {
    display: flex;

    @include media-breakpoint-down(lg) {
      max-width: 175px;
    }

    &.active {
      svg {
        path {
          fill: $c-rain-blue;
        }
      }
    }

    svg {
      path {
        transition: .3s ease;
      }
    }
  }

  &-hamburger {
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 50%;
    border: 2px solid $c-white;
    border-color: $c-white;
    transition: .3s ease;

    @include media-breakpoint-up(lg) {
      display: none;
    }

    &.active {
      &:before,
      &:after {
        background-color: $c-orange-1;
        top: 50%;
      }

      &:before {
        transform: translateX(-50%) rotate(45deg);
      }

      &:after {
        transform: translateX(-50%) rotate(-45deg);
      }
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      width: 19px;
      height: 2px;
      background-color: $c-white;
      transition: .3s ease;
      left: 50%;
    }

    &:before {
      top: calc(50% - 4px);
      transform: translateX(-50%);
    }

    &:after {
      top: calc(50% + 4px);
      transform: translateX(-50%);
    }
  }

  &-nav {
    transition: .3s ease;

    @include media-breakpoint-down(lg) {
      background: $c-white;
      position: absolute;
      width: 100%;
      height: 100vh;
      left: 0;
      top: 0;
      transform: translateX(-100%);
      z-index: -1;
      padding-top: 75px;
    }

    &.active {
      transform: translateX(0);
    }

    ul {
      list-style: none;

      li {
        &:not(:first-of-type) {
          margin-left: 50px;

          @include media-breakpoint-down(lg) {
            margin-left: 25px;
          }
        }

        @include media-breakpoint-down(lg) {
          margin-left: 0!important;
          border-bottom: 1px solid $c-gray-6;

          &:first-of-type {
            border-top: 1px solid $c-gray-6;
          }
        }

        a {
          color: $c-white;
          text-decoration: none;

          @include media-breakpoint-up(lg) {
            text-decoration: underline;
            text-decoration-color: transparent;
            text-decoration-thickness: 0.15em;
            transition: text-decoration-color .3s;
          }

          @include media-breakpoint-down(lg) {
            display: flex;
            align-items: center;
            padding-left: calc(var(--bs-gutter-x, .75rem) / 2);
            height: 68px;
            font-size: 24px;
            transition: .3s ease;
            color: $c-rain-blue;
          }

          &:hover {
            @include media-breakpoint-up(lg) {
              text-decoration-color: inherit;
            }

            @include media-breakpoint-down(lg) {
              text-decoration: none;
              color: $c-white;
              background-color: $c-orange-1;
            }
          }
        }
      }
    }
  }
}