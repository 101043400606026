* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  -webkit-tap-highlight-color: transparent !important;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: $f-brown;
  font-weight: 400;
  color: $c-black;
  font-size: 18px;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  font-family: $f-brown;
  font-weight: 300;
}

picture {
  display: flex;
}

img {
  max-width: 100%;
}

.h2 {
  font-size: 52px;
  line-height: 1.19;
  font-weight: 300;

  @include media-breakpoint-down(md) {
    font-size: 28px;
  }
}

.container {
  @include media-breakpoint-up(xxxl) {
    max-width: calc(1410px + var(--bs-gutter-x, .75rem) * 2);
  }

  &-wide {
    @media (min-width: 1590px) {
      max-width: 1590px;
    }
  }
}

.rain-checkbox {
  display: block;
  position: relative;
  padding-left: 33px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 300;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:not(:last-of-type) {
    margin-right: 25px;

    @include media-breakpoint-down(sm) {
      margin-right: 0;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked {
      ~ .checkmark:after {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .checkmark {
    position: absolute;
    top: 50%;
    left: 0;
    height: 30px;
    width: 30px;
    border: 2px solid $c-gray-4;
    transform: translateY(-50%);
    transition: .3s ease;
    border-radius: 50%;

    &:after {
      content: "";
      position: absolute;
      display: block;
      opacity: 0;
      visibility: hidden;
      top: 50%;
      left: 50%;
      width: 18px;
      height: 18px;
      background-color: $c-rain-orange;
      border-radius: 50%;
      transition: .3s ease;
      transform: translate(-50%, -50%);
    }
  }

  .rain-checkbox-copy {
    display: block;
    transition: .3s ease;
    font-size: 20px;
    margin-left: 15px;
    color: $c-white;
    font-style: normal;
    font-weight: 300;
    letter-spacing: -1px;
  }
}

.rain-btn {
  border-radius: 50px;
  border: 2px solid $c-orange-1;
  text-decoration: none;
  text-transform: uppercase;
  height: 45px;
  display: flex;
  align-items: center;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 14px;
  line-height: 1;
  font-weight: 700;
  color: $c-orange-ada;
  cursor: pointer;
  background-color: transparent;

  span {
    width: 27px;
    height: 14px;
    margin-left: 10px;
    transition: .3s ease;
    position: relative;

    &:before {
      content: "";
      height: 100%;
      position: absolute;
      transition: .3s ease;
      min-width: 27px;
      max-width: 0;
      background-position: right;
      display: block;
      right: 0;
      background-image: url("../images/icon-arrow.svg");
    }
  }

  &:hover {
    span {
      transform: translateX(5px);

      &:before {
        min-width: 22px;
      }
    }
  }

  &-white {
    color: $c-white;
    border-color: $c-white;

    span {
      &:before {
        background-image: url("../images/icon-arrow-white.svg");
      }
    }
  }
}

input {
  &:not([type="radio"]) {
    &::placeholder {
      color: $c-black;
    }
  }

  &[type="radio"] {
    &[disabled] {
      + .checkmark {
        &:after {
          background-color: $c-gray-6;
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}

// reCAPTCHA v3
.grecaptcha-badge {visibility:hidden!important;}

.parallax-parent {
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  overflow: hidden;

  &-img {
    height: 200%;
    width: 100%;
    position: absolute;
    object-fit: cover;
    object-position: center;
  }

  &-container {
    position: absolute;
    overflow: hidden;
  }
}

.parallax-copy,
.parallax-copy-reverse,
.parallax-copy-static {
  position: absolute;
  font-size: 200px;
  color: $c-rain-slate;
  opacity: .08;
  right: -0px;
  top: 0;
  z-index: -2;
  font-weight: 700;

  @include media-breakpoint-down(xxxl) {
    font-size: 175px;
  }

  @include media-breakpoint-down(xl) {
    font-size: 150px;
  }

  @include media-breakpoint-down(lg) {
    font-size: 100px;
  }

  @include media-breakpoint-down(md) {
    opacity: 0;
    visibility: hidden;
  }
}

.parallax-rotated {
  height: 400px;

  @include media-breakpoint-down(xl) {
    height: 250px;
  }

  @include media-breakpoint-down(lg) {
    height: 150px;
  }

  @include media-breakpoint-up(md) {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
  }

  @include media-breakpoint-down(md) {
    transform: unset !important;
    height: auto;
  }
}

.parallax-container {
  width: 0;
  height: 0;
}

.rotated {
  display: flex;
  color: $c-rain-slate;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  margin: 0;
  line-height: 1;
  font-size: 14px;
  white-space: nowrap;

  @include media-breakpoint-up(md) {
    transform: rotate(-90deg);
  }
}

.rain-link {
  &-orange {
    text-decoration: underline;
    text-decoration-color: transparent;
    text-decoration-thickness: .15em;
    transition: text-decoration-color .3s;
    color: $c-orange-1;
    font-weight: 400;

    &:hover {
      text-decoration-color: $c-orange-1;
    }
  }

  &-inherit {
    color: inherit;
    text-decoration: underline;
    text-decoration-color: transparent;
    text-decoration-thickness: .15em;
    transition: text-decoration-color .3s;

    &:hover {
      text-decoration-color: inherit;
    }
  }
}

.no-scroll {
  overflow: hidden;
}