.rain-footer {
  position: relative;
  padding-bottom: 50px;
  height: 850px;
  overflow: hidden;

  @include media-breakpoint-between(md, lg) {
    height: 960px;
  }

  @include media-breakpoint-down(md) {
    padding-bottom: 25px;
  }

  .rain-home-footer-path {
    position: absolute;
    width: 100%;
    height: 100%;
    clip-path: url(#svgPathFooterDesktop);
    overflow: hidden;
    left: 0;
    top: 0;
    z-index: -1;

    @include media-breakpoint-down(md) {
      clip-path: url(#svgPathFooterMobile);
      width: 100%;
    }

    &-img {
      background-color: $c-dark-blue;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      background-size: cover;
      background-position: bottom;
    }
  }

  &__row {
    &-top {
      margin-bottom: 100px;

      @include media-breakpoint-down(xl) {
        margin-bottom: 50px;
      }

      @include media-breakpoint-down(lg) {
        margin-bottom: 25px;
      }
    }
  }

  &__col {
    &-logo {
      a {
        margin-bottom: 40px;
        display: block;
      }

      .h2 {
        font-size: 34px;
        line-height: 1.47;
        color: $c-white;

        @include media-breakpoint-down(xxl) {
          font-size: 30px;
        }

        @include media-breakpoint-down(xl) {
          font-size: 25px;
        }

        @include media-breakpoint-down(md) {
          font-size: 18px;
          line-height: 1.25;
          max-width: 250px;
          margin-bottom: 40px;
        }
      }
    }

    &-links {
      h3 {
        color: $c-green;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 14px;
        line-height: 1;
        margin-bottom: 15px;
      }

      ul {
        list-style: none;

        li {
          max-width: 100%;
          width: 100%;
          display: flex;

          &:not(:last-of-type) {
            margin-bottom: 15px;
          }

          @include media-breakpoint-down(xl) {
            max-width: 50%;
            width: 100%;
          }

          a {
            display: block;
            color: $c-white;
            font-size: 26px;
            line-height: 1.23;
            font-weight: 300;

            &:hover {
              @media(hover: hover) and (pointer: fine) {
                text-decoration-color: $c-white;
              }
            }

            @include media-breakpoint-down(md) {
              font-size: 18px;
            }
          }
        }
      }
    }

    &-social {
      @include media-breakpoint-down(xl) {
        margin-top: 25px;
      }

      h3 {
        color: $c-green;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 14px;
        line-height: 1;
        margin-bottom: 15px;

        @include media-breakpoint-down(lg) {
          max-width: 175px;
          line-height: 1.5;
        }
      }

      ul {
        list-style: none;
        margin-bottom: 80px;

        @include media-breakpoint-down(xl) {
          margin-top: 25px;
          margin-bottom: 0;
          order: 3;
        }

        li {
          &:not(:last-of-type) {
            margin-right: 30px;
          }

          a {
            display: block;

            svg {
              filter: brightness(0) invert(1);
            }

            &:hover {
              svg {
                @media(hover: hover) and (pointer: fine) {
                  filter: unset;
                }
              }
            }

            svg {
              transition: .3s ease;
            }
          }
        }
      }
    }

    &-copyright {
      p {
        color: $c-green;
        font-size: 16px;
        margin: 0;
        line-height: 1;

        @include media-breakpoint-down(xl) {
          max-width: 250px;
        }
      }
    }

    &-security {
      @include media-breakpoint-down(xl) {
        margin-bottom: 25px;
      }

      ul {
        list-style: none;

        li {
          @include media-breakpoint-up(lg) {
            margin-bottom: 0;

            &:not(:last-of-type) {
              margin-right: 15px;
            }
          }

          @include media-breakpoint-down(lg) {
            &:not(:last-of-type) {
              margin-bottom: 15px;
            }
          }

          .rain-link-inherit {
            color: $c-green;
            font-size: 16px;
            border: none;
            outline: none;
            background-color: transparent;
            border-radius: 0;
            font-family: $f-brown;
            cursor: pointer;

            &:hover {
              text-decoration-color: $c-green;
            }

            //OneTrust Overrides
            &#ot-sdk-btn {
              color: $c-green !important;
              font-family: $f-brown !important;
              font-size: 16px !important;
              line-height: 16px !important;
              padding: 0px !important;
              border: none !important;
              outline: none !important;
              transition: none !important;

              &:hover {
                text-decoration-color: $c-green !important;
                background-color: transparent !important;
              }
            }
          }
        }
      }
    }
  }
}