.rain-vip-questions {
  padding-top: 50px;
  margin-top: 150px;

  @include media-breakpoint-down(md) {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  &__col {
    &-rotated {
      @include media-breakpoint-down(md) {
        margin-bottom: 20px;
      }
    }

    &-copy {
      .h2 {
        @include media-breakpoint-up(md) {
          font-size: 52px;
        }

        @include media-breakpoint-up(lg) {
          font-size: 62px;
        }
      }

      .h3 {
        margin: 60px 0 30px;
        font-size: 32px;
        line-height: 1.19;
        font-weight: 300;

        @include media-breakpoint-down(md) {
          margin: 30px 0 10px;
          font-size: 24px;
        }
      }

      p {
        font-size: 18px;
        line-height: 1.4;
        font-weight: 300;

        &:not(:last-of-type) {
          margin-bottom: 20px;
        }
      }
    }
  }
}