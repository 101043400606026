.fade-up {
  opacity: 0;
  transform: translateY(6.25rem);

  &.in-view {
    opacity: 1;
    transform: translateY(0);
    transition: all 2s ease;
  }

  &-children {
    &.in-view {
      .fade-up-children-nth {
        opacity: 1;
        transform: translateY(0);

        @for $i from 2 through 50 {
          &:nth-of-type(#{$i}) {
            transition-delay: calc(#{$i} * .2s);
          }
        }

        &-fast {
          opacity: 1;
          transform: translateY(0);

          @for $i from 2 through 50 {
            &:nth-of-type(#{$i}) {
              transition-delay: calc(#{$i} * .05s);
            }
          }
        }
      }
    }

    &-nth {
      opacity: 0;
      transform: translateY(6.25rem);
      transition: all 2s ease;
      display: block;

      &-fast {
        opacity: 0;
        transform: translateY(6.25rem);
        transition: all 2s ease;
      }
    }
  }
}