.rain-vip-experience {
  padding-top: 50px;
  margin-top: 50px;

  @include media-breakpoint-down(md) {
    margin-top: 10px;
  }

  &__row {
    &-thumb {
      @include media-breakpoint-down(lg) {
        margin: 0;
        justify-content: space-between;
      }
    }
  }

  &__col {
    &-title {
      .h2 {
        margin-bottom: 60px;

        @include media-breakpoint-down(md) {
          margin-bottom: 30px;
        }
      }
    }

    &-swiper {
      position: relative;

      .rain-vip-experience-swiper {
        margin-bottom: 50px;

        @include media-breakpoint-down(md) {
          margin-bottom: 25px;
        }

        .swiper-slide {
          display: flex;
          justify-content: center;
        }

        &-thumbs {
          max-width: calc(100% - 155px);

          @include media-breakpoint-down(md) {
            max-width: calc(100% - 115px);
          }

          .swiper-slide {
            width: auto !important;
            display: flex;

            &-thumb-active {
              img {
                opacity: .5;
              }
            }

            img {
              max-height: 126px;
              transition: .3s ease;

              @include media-breakpoint-down(md) {
                max-height: 60px;
              }
            }
          }
        }
      }

      .swiper-button {
        &-next,
        &-prev {
          background-image: url("/images/icon-arrow.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: 30px;
          width: 60px;
          height: 60px;
          border: 2px solid $c-orange-1;
          border-radius: 50%;
          transition: .3s ease;
          bottom: 35px;
          top: unset;

          @include media-breakpoint-down(md) {
            width: 40px;
            height: 40px;
            background-size: 20px;
            bottom: 10px;
          }

          &:after {
            display: none;
          }
        }

        &-next {
          right: calc(var(--bs-gutter-x) * .5);
        }

        &-prev {
          left: calc(var(--bs-gutter-x) * .5);
          transform: rotate(180deg);
        }
      }
    }
  }
}