.rain-vip-hero {
  position: relative;
  height: 100vh;

  @include media-breakpoint-down(md) {
    height: 500px;
  }

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 10.5vw;
    left: 0;
    bottom: -1px;
    background-image: url("../images/hero-wave.svg");
    z-index: -1;
    background-size: cover;
    background-position: center;
  }

  .parallax-parent {
    picture {
      display: flex;
      position: absolute;
      top: -55%;
      height: 110vh;
      width: 100%;

      @include media-breakpoint-down(md) {
        height: 600px;
        bottom: 40%;
      }
    }

    &-img {
      object-position: top;
      position: relative;
      height: 100%;

      @include media-breakpoint-down(md) {
        object-position: 55% 50%;
      }
    }

    &-container {
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: -2;
    }
  }

  &__col {
    @include media-breakpoint-down(md) {
      margin-bottom: 100px;
    }

    span {
      color: $c-white;
      font-size: 22px;

      @include media-breakpoint-down(md) {
        font-size: 13px;
      }
    }

    img {
      margin: 0 25px;

      @include media-breakpoint-down(md) {
        height: 62px;
        margin: 0 10px;
      }
    }

    .h1 {
      font-size: 72px;
      font-weight: 300;
      line-height: 1.13;
      letter-spacing: -1px;
      color: $c-white;
      margin: 30px 0 20px;

      @include media-breakpoint-down(lg) {
        font-size: 60px;
      }

      @include media-breakpoint-down(md) {
        font-size: 28px;
        max-width: 200px;
        margin: 10px 0 20px;
      }
    }

    p {
      font-size: 24px;
      line-height: 2.08;
      letter-spacing: 0.96px;
      color: $c-white;
      margin-bottom: 30px;

      @include media-breakpoint-down(md) {
        font-size: 16px
      }

      @include media-breakpoint-down(sm) {
        max-width: 250px;
        line-height: normal;
        margin-bottom: 20px;
      }
    }
  }
}