.rain-vip-agenda {
  position: relative;
  overflow: hidden;
  padding: 250px 0 75px;

  @include media-breakpoint-down(xxxl) {
    padding: 225px 0 75px;
  }

  @include media-breakpoint-down(xl) {
    padding: 200px 0 50px;
  }

  @include media-breakpoint-down(lg) {
    padding: 100px 0 0;
    margin-bottom: 50px;
  }

  @include media-breakpoint-down(lg) {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
    padding: 50px 0 30px;
    margin-bottom: 0;
  }

  .parallax-copy {
    display: flex;
    flex-direction: column;
    line-height: .8;
    right: 325px;

    @include media-breakpoint-down(xl) {
      right: 225px;
    }

    @include media-breakpoint-down(lg) {
      display: none;
    }

    span {
      position: relative;
      left: 375px;
      bottom: 15px;

      @include media-breakpoint-down(xxxl) {
        left: 320px;
      }

      @include media-breakpoint-down(xl) {
        left: 275px;
      }

      @include media-breakpoint-down(lg) {
        left: 185px;
        bottom: 10px;
      }
    }
  }

  &-path {
    clip-path: url(#svgPathCbwf);
    position: absolute;
    overflow: hidden;
    right: 0;
    transform: translateY(-50%);
    z-index: -1;
    width: 100%;
    top: 850px;
    aspect-ratio: 482/375;
    max-width: 964px;

    @include media-breakpoint-down(onek) {
      max-width: 50vw;
    }

    @include media-breakpoint-down(lg) {
      max-width: 450px;
      top: 0;
      position: relative;
      transform: unset;
      right: 0;
    }

    @include media-breakpoint-down(lg) {
      max-width: 95vw;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__col {
    &-copy {
      @include media-breakpoint-down(lg) {
        margin-bottom: 30px;
      }

      h2 {
        font-size: 42px;
        font-weight: 400;
        line-height: 1.47;
        letter-spacing: -1px;
        color: $c-rain-blue;

        @include media-breakpoint-down(xl) {
          font-size: 34px;
        }

        @include media-breakpoint-down(lg) {
          font-size: 28px;
        }
      }

      h3 {
        font-size: 28px;
        font-style: italic;
        font-weight: 300;
        line-height: 1.35;
        letter-spacing: -1px;
        margin-bottom: 30px;
      }

      h4 {
        font-size: 18px;
        font-weight: 700;
        line-height: 1.77;
        letter-spacing: -1px;
        color: $c-rain-blue;
        margin-bottom: 15px;
      }

      p {
        font-size: 18px;
        line-height: 1.77;
        font-weight: 300;
        margin-bottom: 20px;

        strong {
          font-weight: 400;
          margin-bottom: 30px;
        }
      }
    }
  }
}