.rain-vip-form {
  background-color: $c-dark-blue;
  padding: 75px 0;

  @include media-breakpoint-down(md) {
    padding: 40px 0;
  }

  h2,
  p {
    color: $c-white;
  }

  &__col {
    &-title {
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      @include media-breakpoint-down(lg) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      h2 {
        font-size: 52px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -1px;
        min-width: 200px;
        max-width: 200px;

        @include media-breakpoint-down(xl) {
          min-width: 175px;
          max-width: 175px;
        }

        @include media-breakpoint-down(lg) {
          min-width: unset;
          max-width: unset;
        }
      }

      p {
        font-size: 20px;
        font-weight: 300;
        line-height: normal;
        letter-spacing: -1px;

        @include media-breakpoint-down(lg) {
          font-size: 18px;
        }
      }
    }

    &-form {
      &-col {
        &:not(:last-of-type) {
          @include media-breakpoint-down(lg) {
            margin-bottom: 30px;
          }
        }
      }

      p {
        min-width: 200px;
        max-width: 200px;
        padding-right: 15px;
        font-size: 18px;
        font-weight: 300;
        letter-spacing: -1px;
        display: flex;
        align-items: center;
        margin-top: 5px;

        @include media-breakpoint-down(xl) {
          min-width: 175px;
          max-width: 175px;
        }

        @include media-breakpoint-down(lg) {
          min-width: unset;
          max-width: unset;
          margin-bottom: 15px;
        }

        br {
          @include media-breakpoint-down(lg) {
            display: none;
          }
        }

        &.rain-vip-form-row-no-margin {
          margin-top: 0;
        }

        &.rain-vip-form-row-negative-margin {
          margin-top: -18px;

          @include media-breakpoint-down(lg) {
            margin-top: 0;
          }
        }
      }

      .rain-vip-form {
        &-fields {
          width: 100%;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;

          .rain-checkbox {
            &:not(:last-of-type) {
              @include media-breakpoint-down(xl) {
                margin-right: 15px;
              }
            }
          }

          label {
            &.error {
              color: $c-error;
              font-size: 14px;
              line-height: normal;
              font-weight: 300;
              width: 100%;
              display: flex;
              padding-top: 5px;
            }
          }

          > div {
            &:not(:last-of-type) {
              margin-bottom: 15px;
            }
          }

          &-row {
            display: flex;
            margin-right: calc(-.5* var(--bs-gutter-x));
            margin-left: calc(-.5* var(--bs-gutter-x));

            > div {
              max-width: 50%;
              width: 100%;
              padding-right: calc(var(--bs-gutter-x)* .5);
              padding-left: calc(var(--bs-gutter-x)* .5);
            }
          }
        }

        &-row {
          display: flex;
          align-items: flex-start;

          @include media-breakpoint-down(lg) {
            flex-direction: column;
          }

          &:not(:last-of-type) {
            margin-bottom: 15px;

            @include media-breakpoint-down(lg) {
              margin-bottom: 30px;
            }
          }

          input {
            &[type="text"],
            &[type="email"] {
              background-color: transparent;
              border: 1px solid rgba(190, 187, 179, 0.50);
              height: 32px;
              width: 100%;
              padding: 0 10px;
              font-size: 16px;
              font-weight: 300;
              line-height: normal;
              letter-spacing: -1px;
              color: $c-gray-7;

              &::placeholder {
                color: $c-gray-7;
              }
            }

            &[disabled] {
              background-color: $c-gray-6;
              background-image: none;
            }
          }

          textarea {
            background-color: transparent;
            border: 1px solid rgba(190, 187, 179, 0.50);
            width: 100%;
            padding: 10px;
            font-size: 16px;
            font-weight: 300;
            line-height: normal;
            letter-spacing: -1px;
            color: $c-gray-7;

            &[disabled] {
              background-color: $c-gray-6;
              background-image: none;
            }
          }
        }
      }
    }
  }
}